<template>
  <div class="cx-root">
    <div>
      <tex>用户管理</tex>
      <el-button class="cx-add-btn" type="warning" plain @click="addUser">新增用户</el-button>
      <el-button class="cx-add-btn" type="primary" icon="el-icon-download" @click="exportExcel">导出</el-button>

      <el-upload
          class="cx-add-btn"
          :file-list="fileList"
          :auto-upload="false"
          :on-change="handleChange"
          :on-exceed="handleExceed"
          multiple = false
          :limit="1">
        <el-button  type="success" plain>上传<i class="el-icon-upload el-icon--right"></i></el-button>
      </el-upload>
      <el-button class="cx-add-btn" type="danger" icon="el-icon-delete" @click="delAll">批量删除</el-button>

      <el-button plain class="cx-add-btn2" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
      <el-input
          class="queryInput"
          placeholder="请输入姓名/学号"
          v-model.trim="queryUser">
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>

    </div>

    <el-divider></el-divider>

    <el-table
        :data="tableData"
        @selection-change="handleSelectionChange"
        style="width: 100%">
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column
          prop="stuNumber"
          label="学号/工号">
      </el-table-column>
      <el-table-column
          prop="nickname"
          label="姓名">
      </el-table-column>
      <el-table-column
          prop="phoneNumber"
          label="手机号码">
      </el-table-column>
      <el-table-column
          prop="userType"
          label="用户类型">
      </el-table-column>
      <el-table-column
          prop="openId"
          label="openId">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="warning"
              @click="unbindUser(scope.$index, scope.row)">解绑
          </el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleEdit(scope.$index, scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="新增用户" :visible.sync="dialogFormVisible" width="30%">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="名称" prop="nickname">
          <el-input v-model="form.nickname"></el-input>
        </el-form-item>
        <el-form-item label="学号/工号" prop="stuNumber">
          <el-input v-model="form.stuNumber"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phoneNumber">
          <el-input v-model="form.phoneNumber"></el-input>
        </el-form-item>
        <el-form-item label="用户类型" prop="userType">
          <el-radio-group v-model="form.userType">
            <el-radio label="1">学生</el-radio>
            <el-radio label="2">老师</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveUser('form')">确 定</el-button>
      </div>
    </el-dialog>

  </div>


</template>

<script>
export default {
  name: "user",
  data() {
    return {
      queryUser:'', // 查询名称
      fileList: [],
      tableData: [],
        multipleSelection: [],
      dialogFormVisible: false, // 新增用户弹窗
      form: {nickname: '', stuNumber: '',phoneNumber:'', userType: "1"},
      userType: ['','学生', '老师'],
      rules: {
        nickname: [
          {required: true, message: '请输入名称', trigger: 'blur'},
        ],stuNumber: [
          {required: true, message: '请输入学号/工号', trigger: 'blur'},
        ],phoneNumber: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {pattern:/^(13|14|15|16|17|18|19)\d{9}$/,message: '手机号格式不对',trigger: 'blur'}
        ]
      }
    }
  },
  created() {
    this.list()
  },
  methods: {
    list() {
      this.$apiUtil.Manage.user(null, res => {
        this.tableData = res.data;
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i].userType = this.userType[this.tableData[i].userType]
        }
      })
    },

    handleChange (file, fileList) {
      this.fileList = [];
      // 检查文件类型是否是 xlsx 或 xls
      const isExcel =
          file.name.endsWith(".xlsx") ||
          file.name.endsWith(".xls");
      if (!isExcel) {
        this.$message.error("仅支持上传 .xlsx 或 .xls 格式的文件!");
        return;
      }
      // 检查文件大小是否符合限制
      const isSizeValid = file.size / 1024 / 1024 < 10;
      if (!isSizeValid) {
        this.$message.error("文件大小不能超过10MB");
        return;
      }
      const formData = new FormData();
      formData.append("file", file.raw);
      this.$apiUtil.Upload.uploadFile(formData, res => {
        this.$message({
          message: '上传成功',
          type: 'success',
          center: true,
          duration: 1000,
          onClose: () => {
            this.list();
          }
        });
      })

    },
    handleExceed(){
      this.$message({
        message: '只能上传一个文件',
        type: 'error',
        center: true,
        duration: 1000,
      });
    },

    /**
     * 搜索
     */
    search(){
      if(this.queryUser.trim()){
        this.$apiUtil.Manage.search({'keyword':this.queryUser}, res => {
          this.tableData = res.data;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].userType = this.userType[this.tableData[i].userType]
          }
        })
      }else {
        this.list();
      }

    },

    handleEdit(index, row) {
      this.$confirm('是否删除用户？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$apiUtil.Manage.delUser({userId: row.id}, res => {
          this.$message({
            message: '删除成功',
            type: 'success',
            center: true,
            duration: 1000,
            onClose: () => {
              this.list();
            }
          });
        })
      }).catch(() => {
      });
    },

    /**
     * 解绑
     * @param index
     * @param row
     */
    unbindUser(index, row) {
      this.$confirm('是否解绑该用户？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$apiUtil.Manage.unbind({userId: row.id}, res => {
          this.$message({
            message: '解绑成功',
            type: 'success',
            center: true,
            duration: 1000,
            onClose: () => {
              this.list();
            }
          });
        })
      }).catch(() => {});
    },

    addUser(){
      this.dialogFormVisible = true
    },

    /**
     * 导出表格
     */
    exportExcel(){
      const data = this.formatData(this.tableData) // 将数据格式化为xlsx可以识别的格式
      const wb = this.$XLSX.utils.book_new()
      const ws = this.$XLSX.utils.json_to_sheet(data)
      this.$XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      this.$XLSX.writeFile(wb, '用户数据.xlsx')
    },
    formatData(data) {
      // 格式化表格数据，将对象数组转换为数组对象
      return data.map(item => {
        const { nickname, stuNumber, openId ,userType} = item
        return { 姓名: nickname, 学号: stuNumber, 微信openId: openId ,用户类型:userType}
      })
    },

    /**
     * 批量删除
     */
    delAll(){
      if(this.multipleSelection.length === 0){
        this.$message.error("请选择要被删除的人员！")
        return;
      }
      this.$confirm('是否删除选中的 ' + this.multipleSelection.length + '人？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let list = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          list.push(this.multipleSelection[i].id)
        }
        console.log(list);
        this.$apiUtil.Manage.delUserAll(list, res => {
          this.$message({
            message: '删除成功',
            type: 'success',
            center: true,
            duration: 1000,
            onClose: () => {
              this.list();
            }
          });
        })
      }).catch(() => {});
    },

    /**
     * 全选
     */
    handleSelectionChange(val){
      this.multipleSelection = val;
    },

    saveUser(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$apiUtil.Manage.addUser(this.form, res => {
            this.$message({
              message: '保存成功',
              type: 'success',
              center: true,
              duration: 1000,
              onClose: () => {
                this.dialogFormVisible = false
                this.list();
              }
            });
          })
        } else {
          return false;
        }
      });
    }
  },

}
</script>

<style scoped>

.cx-root {
  padding: 1% 5%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
}

.cx-add-btn {
  float: right;
  margin-right:30px;
}
.cx-add-btn2 {
  float: right;
  margin-right:80px;
}
.queryInput{
  width: 200px;
  float: right;
  margin-right:10px;
}

</style>