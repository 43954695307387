<template>
  <div class="cx-root">
    <div class="cx-body">
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
        <h1>会议室管理后台</h1>
<!--        <h1>—</h1>-->
<!--        <h2>登录</h2>-->

        <el-form-item prop="username">
          <el-input
              v-model="loginForm.username"
              auto-complete="off"
              placeholder="请输入管理员账号"
              style="width: 63%"
          >
            <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon"/>
          </el-input>
        </el-form-item>
          <el-form-item prop="password">
            <el-input
                v-model="loginForm.password"
                auto-complete="off"
                placeholder="请输入密码"
                style="width: 63%"
                @keyup.enter.native="handleLogin"
            >
              <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon"/>
            </el-input>
          </el-form-item>
            <el-form-item prop="captchaCode">
              <el-input
                  @keyup.enter.native="handleLogin"
                  v-model="loginForm.captchaCode"
                  auto-complete="off"
                  placeholder="验证码"
                  style="width: 63%"
              >
                <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon"/>
              </el-input>
              <div class="login-code">
                <img :src="codeUrl" @click="getCode" class="login-code-img"/>
              </div>
            </el-form-item>

        <el-form-item style="width:100%;">
          <el-button
              :loading="loading"
              size="medium"
              type="primary"
              style="width:100%;"
              @click.native.prevent="handleLogin"
          >
            <span v-if="!loading">登 录</span>
            <span v-else>登 录 中...</span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

export default {
  name: "login",
  data() {
    return {
      loading: false,
      loginForm: {
        username: "",
        password: "",
        captchaCode: "",
        captchaId: ""
      },
      codeUrl: "",
      loginRules: {
        username: [
          {required: true, trigger: "blur", message: "请输入您的账号"}
        ],
        password: [
          {required: true, trigger: "blur", message: "请输入您的密码"}
        ],
        captchaCode: [{required: true, trigger: "change", message: "请输入验证码"}]
      },
    };
  },
  created() {
    this.getCode();
  },
  methods: {
    /** 获取验证码 */
    getCode() {
      this.$apiUtil.Manage.captcha(null, res => {
        let base64 = "data:image/png;base64,"
        let data = res.data;
        this.codeUrl = base64 + data.image;
        this.loginForm.captchaId = data.id;
      })
    },
    /** 登录 */
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.$apiUtil.Manage.login(this.loginForm, res => {
            let data = res.data;
            // this.$apiUtil.Cookie.setCookie("authentication", data.token);
            this.$cookies.set("manageToken", data.token);
            this.$cookies.set("manageNickname", data.nickname);
            this.$router.push({path: "/manage/"});
          },err => {
            this.$message.error(err.message);
            this.getCode();
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.cx-root{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: url("@/assets/login-background.jpg");
  background-size: 100% 100%;
}
.cx-body {

  h1 {
    font-size: 3em;
    color: #693e1b;
    text-align: center;
  }

  h2 {
    font-weight: normal;
    font-size: 2em;
  }

  /deep/ .el-input__inner {
    border: none;
    background-color: rgba(255, 255, 255, 0);
  }

  i {
    color: #693e1b;
  }

  .el-button {
    background-color: #693e1b;
    color: #fff;
    border: none;
    margin-top: 20px;
    width: 100%;
  }
}
.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 400px;
  padding: 25px 25px 5px 25px;
  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}

.login-code {
  width: 33%;
  height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
.login-code-img {
  height: 38px;
}
</style>