const Version = '1.0';// 版本号

// const BaseUrl = 'http://localhost:8082/api'; // 测试
const BaseUrl = 'https://www.nju.asia/api/'; // 发布
// const BaseUrl = 'http://ceastar-sta:8890/'; // 发布
// const BaseUrl = 'http://localhost:8081/api/'; // 测试
// const BaseUrl = 'https://meettest.haitim.cn/api'; // 发布



const LoadingWords = '加载中...';// 加载中文字介绍
const ErrorMsg = '网络错误';//


export default {
    Version,
    BaseUrl,
    LoadingWords,
    ErrorMsg,
}
