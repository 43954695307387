<template>
  <div class="app-container">
    <h1>微信扫码登录</h1>
    <div id="qrcode"></div>
  </div>
</template>

<script>
export default {
  created() {
    // let data = {
    //   "stuNumber": "9527",
    //   "nickname": "刘海天",
    //   "token": "UrRUUqsqha6uDRfWiPc8gMIVn75usUxl"
    // }
    // this.$cookies.set("userToken", data.token);
    // this.$cookies.set("nickname", data.nickname);
    // this.$cookies.set("stuNumber", data.stuNumber);
    // this.$cookies.set("phoneNumber", 13913944968);
    // this.$router.push({path: "/"});

    let code = this.$apiUtil.getUrlKey("code")
    if(code){
      this.$apiUtil.User.webLogin({code:code},res => {
        let data = res.data;
        this.$cookies.set("userToken", data.token);
        this.$cookies.set("nickname", data.nickname);
        this.$cookies.set("stuNumber", data.stuNumber);
        this.$cookies.set("phoneNumber", data.phoneNumber);
        this.$router.push({path: "/"});

      },err=>{
        this.$cookies.remove("userToken")
        this.$cookies.remove("nickname");
        this.$cookies.remove("stuNumber");
        this.$cookies.remove("phoneNumber");
      })
    }
  },

  mounted() { // 注意调用方法生命周期不能为created，否则不会加载出来
    this.wxCode();
  },
  methods: {
    wxCode() {
      var obj = new WxLogin({
        // 是否开启新页面展示登录二维码
        self_redirect: false,
        // html中要展示二维码的标签id
        id: "qrcode",
        // 网站应用的AppID
        appid: "wxb634ba2966772a55",
        scope: "snsapi_login",
        // 回调地址，注意一定要在微信平台下的授权回调域域名下，否则会报redirect_uri错误
        // 且要用encodeURIComponent()方法转码
        // 在此我采用的是自己配置一个wxlogin路由页面做中间页面用于请求后端及结果处理
        redirect_uri: encodeURIComponent('https://www.nju.asia/login'),
        // state参数用于二次检验，可自行设置随机字符串等
        state: Math.ceil(Math.random() * 1000),
        style: "black",
      });
    },
  },
};
</script>

<style scoped>
.app-container {
  height: 800px;
  position: relative;
  text-align: center;
}
#qrcode {
  text-align: center;
}
.impowerBox .status_txt {
  color: black !important;
}
</style>
