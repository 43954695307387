<template>
  <div class="cx-root">
    <div>
      <tex>预定管理</tex>
    </div>

    <el-divider></el-divider>


    <el-row>
      <el-col :span="10">
        <el-calendar v-model="date" id="calendar">
          <template
              slot="dateCell"
              slot-scope="{data}">
            <!--自定义内容-->
            <div>
              <div class="calendar-day">{{ data.day.split('-').slice(2).join('-') }}</div>
            </div>
          </template>
        </el-calendar>
      </el-col>

      <el-col :span="13" :offset="1">
        <el-table
            :data="tableData"
            style="width: 100%">
          <el-table-column
              prop="roomName"
              label="会议室名称"
          >
          </el-table-column>
          <el-table-column
              prop="time"
              label="时间段"
              width="180">
          </el-table-column>
          <el-table-column
              prop="meetName"
              label="会议名称">
          </el-table-column>
          <el-table-column
              prop="nickname"
              label="申请人">
          </el-table-column>
          <el-table-column
              prop="stuNumber"
              label="学号">
          </el-table-column>
          <el-table-column
              prop="phoneNumber"
              label="手机号">
          </el-table-column>
          <el-table-column label="操作" min-width="120px">
            <template slot-scope="scope">
              <div v-if="scope.row.audit===0">
                <el-button
                    size="mini"
                    type="success"
                    @click="handleEdit(1,scope.$index, scope.row)">通过
                </el-button>
                <el-button
                    size="mini"
                    type="danger"
                    @click="handleEdit(2,scope.$index, scope.row)">拒绝
                </el-button>
              </div>
              <div v-if="scope.row.audit===1">
                <el-tag type="success">审核通过</el-tag>
              </div>
              <div v-if="scope.row.audit===2">
                <el-tag type="danger">【已拒绝】{{ scope.row.auditReason }}</el-tag>
              </div>

            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>


  </div>
</template>

<script>
export default {
  name: "reserve",
  data() {
    return {
      date: new Date(),
      dateStr: '',
      tableData: [],
      showCalendar: true
    }
  },
  created() {
    this.initDate()
    this.list()
  },
  methods: {
    list() {
      this.$apiUtil.Manage.dating({date: this.dateStr}, res => {
        this.tableData = res.data;
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i].time = this.tableData[i].startTime.substr(11,5) + '-' + this.tableData[i].endTime.substr(11,5)
        }
      })
    },

    /**
     * 初始化日期
     */
    initDate() {
      let year = this.date.getFullYear();
      let month = this.date.getMonth() + 1;
      let date = this.date.getDate()
      if (date >= 1 && date <= 9) {//日如果小于10就补个0
        date = "0" + date;
      }
      if (month >= 1 && month <= 9) {//月如果小于10就补个0
        month = "0" + month;
      }
      this.dateStr = year + '-' + month + '-' + date;
    },

    /**
     * 审核会议室
     * @param data
     */
    audit(data){
      this.$apiUtil.Manage.datingAudit(data,res=>{
        this.$message({
          message: '保存成功',
          type: 'success',
          center: true,
          duration: 1000,
          onClose: () => {
            this.list();
          }
        });
      })
    },

    /**
     * 审核
     */
    handleEdit(audit,index,row){
      if(audit===1){
        // 通过
        this.$confirm('确认通过 '+row.nickname + ' 申请的 ' + row.roomName, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {
            id:row.id,
            audit:audit
          }
          this.audit(data);
        }).catch(() => {});
      }else {
        // 拒绝
        this.$prompt('请输入审核原因', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(({ value }) => {
          if(value.trim()){
            let data = {
              id:row.id,
              audit:audit,
              auditReason:value,
            }
            this.audit(data);
          }else {
            this.$message.error('请输入审核原因');
          }
        }).catch(() => { });
      }
    },
  },
  /**
   * 时间转换
   * @param dateString
   * @returns {string}
   */
  convertToHourMinute(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return hours + ':' + minutes;
  },



  watch: {
    date: function () {
      let year = this.date.getFullYear();
      let month = this.date.getMonth() + 1;
      let date = this.date.getDate()
      if (date >= 1 && date <= 9) {//日如果小于10就补个0
        date = "0" + date;
      }
      if (month >= 1 && month <= 9) {//月如果小于10就补个0
        month = "0" + month;
      }
      this.dateStr = year + '-' + month + '-' + date;
      this.list()
    }
  },

}
</script>

<style scoped>
.cx-root {
  padding: 1% 5%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
}

.el-calendar {
  text-align: center;
}

.temp {
  padding: 20px;
}

.is-selected-1 {
  width: 100%;
  height: 100%;
}

.el-calendar-table td.is-selected{
  background-color: #e6a23c !important;
}

</style>