import {Message,Loading } from 'element-ui';

// 引入框架
import axios from 'axios'

import cookies from "vue-cookies";
import router from '../router'

// 引入自定义
import config from '/public/config'

/******************************      公共函数       *****************************/
// axios配置
axios.defaults.baseURL = config.BaseUrl;
axios.defaults.timeout = 30000;
// 全局设置 axios 发送请求带上cookie
axios.defaults.withCredentials = true;
// 拦截器 - 请求后
axios.interceptors.response.use(function (response) {
    return response;
}, function (err) {
    // console.log(err)
});


/******************************      Server 请求方法       *****************************/

const Server = {
    // 通用请求服务器接口
    getDataFromServer(apiName, data, type, successCallback, errorCallback) {
        var loadingComponent = Loading.service({text:'疯狂加载中...',background:'rgba(0,0,0,0.6)'});
        let token = ''
        if(apiName.startsWith("/manage")){
            token = cookies.get('manageToken');
        }else {
            token = cookies.get('userToken');
        }

        const axiosConfig = {
            headers: {
                'Auth': token,
                'Content-Type': (type === 'POST' || type === 'PUT' || type==='DELETE2') ?  'application/json' : (type === 'UPLOAD')?'multipart/form-data':'application/x-www-form-urlencoded'
            },
            data:true
        };

        let axiosRequest;
        if (type === 'POST') {
            axiosRequest = axios.post(apiName, data, axiosConfig);
        } else if (type === 'GET' ) {
            axiosRequest = axios.get(apiName, {params:data,headers: axiosConfig.headers});
        } else if (type === 'DELETE' ) {
            axiosRequest = axios.delete(apiName, {params:data,headers: axiosConfig.headers});
        }else if(type === 'PUT' || type === 'PUT2'){
            axiosRequest = axios.put(apiName, data, axiosConfig);
        }else if (type === 'POST2' || type === 'UPLOAD') {
            axiosRequest = axios.post(apiName, data, axiosConfig);
        }else if (type === 'DELETE2') {
            axiosRequest = axios.delete(apiName, {data:data,headers: axiosConfig.headers});
        }

        axiosRequest.then(res => {
            loadingComponent.close();
                if (res && (res.status === 200 || res.status === 304)) {
                    if (res.data.code === 200) {
                        successCallback(res.data);
                    } else if (res.data.code === 401) {
                        if(apiName.startsWith("/manage")){
                            router.replace({ path: '/manage/login'});
                        }else {
                            router.replace({ path: '/login'});
                        }
                    } else {
                        Message.error({
                            message: res.data.message,
                            type: 'error',
                            center: true,
                            duration: 2000,
                            onClose:()=>{
                                if(res.data.message === '权限不足,请联系管理员'){
                                    if(apiName.startsWith("/manage")){
                                        router.replace({ path: '/manage/login'});
                                    }else {
                                        router.replace({ path: '/login'});
                                    }
                                }
                                errorCallback(res.data);
                            }
                        });
                    }
                } else {
                    Message.error(config.ErrorMsg);
                    errorCallback(config.ErrorMsg);
                }
            })
            .catch(err => {
                loadingComponent.close();
                Message.error(config.ErrorMsg);
                // console.error(err);
                // errorCallback(err);
            });
    },

    UploadFileByWeb(apiName, data, sucesscallback, errorcallback) {
        let apiUrl = config.BaseUrl + apiName;
        if (window.FormData) {
            let formData = new FormData();
            formData.append('path', data.path);
            formData.append('file', data.file);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', apiUrl);
            //上传完成后的回调
            xhr.onload = function () {
                if (xhr.status === 200) {
                    sucesscallback && sucesscallback(JSON.parse(xhr.responseText))
                } else {
                    errorcallback && errorcallback(JSON.parse(xhr.responseText))
                }
            };
            xhr.send(formData);
        }
    },
}

/******************************      Cookie 模块       *****************************/
const Cookie = {
    //获取cookie、
    getCookie(name) {
        if (document.cookie.length > 0) {
            var start = document.cookie.indexOf(name + "=")
            if (start != -1) {
                start = start + name.length + 1
                var end = document.cookie.indexOf(";", start)
                if (end == -1) end = document.cookie.length
                return unescape(document.cookie.substring(start, end))
            }
        }
        return null
    },
    /********
     * 设置cookie
     */
    setCookie(name, value) {
        document.cookie = name + "=" + escape(value);
    },
    //删除cookie
    delCookie(name) {
        if (Cookie.getCookie(name)) {
            var expdate = new Date();
            expdate.setTime(expdate.getTime() - (86400 * 1000 * 1));
            Cookie.setCookie(name, "", expdate);
        }
    }

};
/******************************       用户      *****************************/
const User = {
    /**
     * [用户端]预约会议室
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    order(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/room/order',
            requestInfo, 'POST2',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },

    /**
     * [用户端]获取会议室列表
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    room(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/room',
            requestInfo, 'GET',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },

    /**
     * [用户端]获取我的预约列表
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    mydate(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/room/mydate',
            requestInfo, 'GET',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },
    /**
     * [用户端]获取某个日期可预约的时间段信息
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    detail(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/room/detail',
            requestInfo, 'GET',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },
    /**
     * [用户端]微信登录
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    webLogin(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/user/webLogin',
            requestInfo, 'POST2',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },

    /**
     * [用户端]order
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    updateOrder(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/room/order',
            requestInfo, 'PUT2',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },
    /**
     * 取消
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    cancel(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/room/cancel',
            requestInfo, 'POST2',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },
    /**
     * [用户端]获取某个年月的属于该用户的预约
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    roomDate(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/room/date',
            requestInfo, 'GET',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },

}


/******************************       管理      *****************************/
const Manage = {

    /**
     * 验证码
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    captcha(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/login/captcha',
            requestInfo, 'GET',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },
    /**
     * 登录
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    login(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/login',
            requestInfo, 'POST',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },
    /**
     * 用户信息
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    user(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/user',
            requestInfo, 'GET',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },

    /**
     * 添加用户
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    addUser(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/user',
            requestInfo, 'POST2',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },
    /**
     * 删除用户
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    delUser(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/user',
            requestInfo, 'DELETE',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },

    /**
     * [管理员端]搜索用户
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    search(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/user/search',
            requestInfo, 'GET',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },

    /**
     * [管理员端]获取会议室列表
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    meetingList(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/list',
            requestInfo, 'GET',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },
    /**
     * [管理员端]获取会议室详情
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    meetingDetail(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/detail',
            requestInfo, 'GET',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },

    /**
     * [管理员端]获取会议室详情
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    addRoom(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/room',
            requestInfo, 'POST',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },

    /**
     * [管理员端]删除会议室
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    delRoom(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/room',
            requestInfo, 'DELETE',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },
    updateRoom(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/room',
            requestInfo, 'PUT',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },

    /**
     * [管理员端]添加会议室时间段
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    addRoomTime(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/room/time',
            requestInfo, 'POST',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },
    /**
     * [管理员端]删除会议室时间段
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    delRoomTime(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/room/time',
            requestInfo, 'DELETE',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },
    /**
     * [管理员端]获取会议室预约列表
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    dating(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/dating',
            requestInfo, 'GET',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },
    /**
     * [管理员端]审核会议室预约
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    datingAudit(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/dating/audit',
            requestInfo, 'POST2',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },
    /**
     * [管理员端]获取首页信息
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    manageIndex(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/index',
            requestInfo, 'GET',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },
    /**
     * 解绑
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    unbind(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/unbind',
            requestInfo, 'POST2',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },
    /**
     * 批量删除
     * @param requestInfo
     * @param successCallback
     * @param errorCallback
     */
    delUserAll(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/user',
            requestInfo, 'DELETE2',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },

}


/******************************      上传模块       *****************************/
const Upload = {
    // 上传图片
    uploadFile(requestInfo, successCallback, errorCallback) {
        Server.getDataFromServer(
            '/manage/upload',
            requestInfo, 'UPLOAD',
            function (rtn) {
                successCallback && successCallback(rtn);
            },
            errorCallback
        )
    },

}

function getUrlKey (name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
}

function getUrlParams() {
    let url = window.location.href
    let index = url.indexOf('?')
    let params = {}
    if (index !== -1) {
        let paramsStr = url.slice(index + 1) // 获取到问号以后的字符串
        if (paramsStr.indexOf("#/") > 0) {
            paramsStr = paramsStr.split("#/")[0]
            // paramsStr = paramsStr.substr(0,paramsStr.length-2)
        }
        let paramsArr = paramsStr.split('&')
        // 把url上的所有参数塞到json对象中,以键值对的方式保存
        for (let i = 0, length = paramsArr.length, param; i < length; i++) {
            param = paramsArr[i].split('=')
            params[param[0]] = param[1]
        }
    }
    return params
}

export default {
    Cookie,
    Manage,
    Upload,
    User,
    getUrlParams,
    getUrlKey
}


