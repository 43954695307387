<template>
  <div class="cx-root">
    <h2>会议日历</h2>
    <el-divider></el-divider>


    <el-row :gutter="10">
      <el-col :span="10">
        <el-calendar v-model="date">
          <template slot="dateCell" slot-scope="{date, data}">
            {{ data.day.split('-').slice(2).join('-') }}
<!--            {{ data.day}}-->
            <div class="schedule" v-if="scheduleData.indexOf(data.day.replaceAll('-',''))>0"></div>
          </template>
        </el-calendar>
      </el-col>

      <el-col :span="13" :offset="1">
        <el-table
            :data="tableData">
          <el-table-column
              prop="roomName"
              label="会议室名称">
          </el-table-column>
          <el-table-column
              prop="roomDescription"
              label="描述">
          </el-table-column>
          <el-table-column
              prop="tag"
              label="设施">
          </el-table-column>

          <el-table-column
              prop="startTime"
              label="开始时间">
          </el-table-column>
          <el-table-column
              prop="endTime"
              label="结束时间">
          </el-table-column>
          <el-table-column label="操作" >
            <template slot-scope="scope">
              <el-button
                  v-if="scope.row.status===1"
                  size="mini"
                  type="primary"
                  @click="handleEdit(scope.$index, scope.row)">预定
              </el-button>

              <el-button
                  v-if="scope.row.status!==1"
                  size="mini"
                  disabled
                  type="danger">已预订
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>


    <el-dialog title="预定" :visible.sync="orderVisible" width="30%">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="会议名称" label-width="120px" prop="meetName">
          <el-input v-model="form.meetName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="预约人" label-width="120px" prop="nickname">
          <el-input v-model="form.nickname" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="学号" label-width="120px" prop="stuNumber">
          <el-input v-model="form.stuNumber" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="手机号" label-width="120px" prop="phoneNumber">
          <el-input v-model="form.phoneNumber" autocomplete="off" type="text" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitOrder">确 定</el-button>
      </div>
    </el-dialog>

  </div>

</template>


<script>
export default {
  name: "calendar",
  data() {
    return {
      date: new Date(),
      list:[],
      tableData:[],
      scheduleData:[],
      id:'',
      orderVisible:false, // 预定框
      form: {
        meetName: '',
        nickname: '',
        stuNumber: '',
        phoneNumber: ''
      },
      rules: {
        meetName: [
          {required: true, message: '请输入会议名称', trigger: 'blur'},
        ],
        nickname: [
          {required: true, message: '请输入预约人', trigger: 'blur'},
        ],
        stuNumber: [
          {required: true, message: '请输入学号', trigger: 'blur'},
        ],
        phoneNumber: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {pattern:/^(13|14|15|16|17|18|19)\d{9}$/,message: '手机号格式不对',trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    this.detail(this.dateToStr());
    this.roomDate(this.dateToStr());
    this.form.nickname = this.$cookies.get('nickname')
    this.form.stuNumber = this.$cookies.get('stuNumber')
    this.form.phoneNumber = this.$cookies.get('phoneNumber')
  },
  methods: {

    handleEdit(index,item){
      this.orderVisible = true;
      this.form.roomId = item.roomId;
      this.form.timeId = item.roomTimeId;
      this.form.date = this.dateToStr();
    },

    submitOrder(){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
            this.$apiUtil.User.order(this.form,res=>{
              this.orderVisible = false;
              this.$message({
                type: 'success',
                message: '预约成功'
              });
              this.detail(this.dateToStr());
            },err=>{

            })
        } else {
          return false;
        }
      });
    },

    roomDate(date){
      let tmpDate = date.substr(0,6)
      this.$apiUtil.User.roomDate({date:tmpDate},res=>{
        this.scheduleData = res.data;
        console.log(res.data)
      })
    },


    detail(date){
      this.$apiUtil.User.detail({date:date},res=>{
        this.tableData = res.data;
      })
    },

    dateToStr(){
      let year = this.date.getFullYear();
      let month = this.date.getMonth() + 1;
      let date = this.date.getDate()
      if (date >= 1 && date <= 9) {//日如果小于10就补个0
        date = "0" + date;
      }
      if (month >= 1 && month <= 9) {//月如果小于10就补个0
        month = "0" + month;
      }
      let dateStr = year + '' + month + '' + date;
      return dateStr;
    }

  },
  watch: {
    date: function () {
      this.detail(this.dateToStr());
      this.roomDate(this.dateToStr());
    }
  }
}
</script>

<style scoped>

.cx-root {
  padding: 1% 5%;
  /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);*/
  /*border-radius: 15px;*/
}
.cx-body-card{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  background-color: white;
  border-radius: 10px;
  padding: 3%;
}

.cx-card {
 min-width: 280px;
  max-width: 350px;
  margin-bottom: 20px;
}
.cx-body{
  background-color: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  width: 90%;
  min-height: 300px;
  margin-top: 50px;
  padding: 2% 5% 5% 5%;

}
.cx-body > p{
  font-weight: bold;
  font-size: 25px;
}
.cx-body-item{
  background-color: #f7dfac;
  border-radius: 25px;
  text-align: center;
  font-size: 12px;
  padding: 2% 4%;
  margin-right: 2%;
  display: inline-block;
}
.cx-divider{
  margin: 10px 0;
}

.cx-body-bottom > img{
  width: 20px;
}
.cx-btn{
  height: 15px;
  line-height: 0;
  float: right;
}
.el-calendar {
  text-align: center;
}
.temp {
  padding: 20px;
}
.is-selected-1 {
  width: 100%;
  height: 100%;
}

.cx-room-desc{
  font-size: 12px;
  color: #888;
}
.schedule{
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 100%;
  margin: auto;
  margin-top: 30%;
}
</style>