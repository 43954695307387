<template>
  <div class="cx-root">
    <div>
      <tex>会议室管理</tex>
      <el-button class="cx-add-btn" type="warning" plain @click="addMeeting()">添加会议室
      </el-button>
    </div>

    <el-divider></el-divider>

    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          prop="roomName"
          label="会议室名称"
      >
      </el-table-column>
      <el-table-column
          prop="roomDescription"
          label="会议室描述"
      >
      </el-table-column>
      <el-table-column
          prop="peopleNum"
          label="容纳人数"
          width="180">
      </el-table-column>
      <el-table-column
          prop="tag"
          label="设施情况">
      </el-table-column>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">编辑
          </el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--   新增/修改 -->
    <el-drawer
        :title="drawerTitle"
        :visible.sync="drawer"
        size="70%"
        direction="rtl"
        :before-close="handleClose">

      <el-row>
        <!--  左侧基本信息 -->
        <el-col :span="10">
          <div class="cx-form-title">会议室信息</div>
          <el-form ref="form" :rules="infoRules" :model="form" label-width="120px">
            <el-form-item label="会议室名称：" prop="roomName">
              <el-input v-model="form.roomName"></el-input>
            </el-form-item>
            <el-form-item label="会议室描述	：" prop="roomDescription">
              <el-input v-model="form.roomDescription"></el-input>
            </el-form-item>
            <el-form-item label="容纳人数：" prop="peopleNum">
              <el-input v-model="form.peopleNum" type="number" maxlength="4" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="设施情况：" prop="tag">
              <el-checkbox-group v-model="form.tag">
                <el-checkbox label="白板" name="type"></el-checkbox>
                <el-checkbox label="投影" name="type"></el-checkbox>
                <el-checkbox label="话筒" name="type"></el-checkbox>
                <el-checkbox label="影像" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="是否可用" prop="isActive">
              <el-radio-group v-model="form.isActive">
                <el-radio :label="0">可用</el-radio>
                <el-radio :label="1">不可用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>

          <div class="cx-save-div">
            <el-button type="success" @click="save">保存</el-button>
          </div>

        </el-col>
        <!--  右侧日期列表 -->
        <el-col :span="13" style="margin-left: 40px">
          <div class="cx-form-title">开放时间段</div>
          <!--  添加时间段 -->
          <el-form ref="formDate" label-width="80px">
            <div class="cx-form-date" v-for="(item,index) in formDate">
              <el-button class="cx-del-btn" type="danger" icon="el-icon-delete" circle size="mini"
                         @click="delItem(item,index)"></el-button>
              <el-form-item label="时间段：" required>
                <el-col :span="10">
                  <el-form-item prop="startTime">
                    <el-time-select
                        style="width: 80%"
                        placeholder="起始时间"
                        v-model="item.startTime"
                        :picker-options="{
                        start: '06:00',
                        step: '00:15',
                        end: '22:00',
                        maxTime: item.endTime
                      }">
                    </el-time-select>
                  </el-form-item>
                </el-col>
                <el-col class="line" :span="3">—</el-col>
                <el-col :span="10">
                  <el-form-item prop="endTime">
                    <el-time-select
                        style="width: 80%"
                        placeholder="结束时间"
                        v-model="item.endTime"
                        :picker-options="{
                    start: '06:00',
                      step: '00:15',
                      end: '22:00',
                    minTime: item.startTime
                  }">
                    </el-time-select>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <el-form-item label="日期：" label-width="65px" prop="dayOfWeek">
                <el-checkbox :indeterminate="item.isIndeterminate" v-model="item.checkAll"
                             @change="handleCheckAllChange($event,index)">全选
                </el-checkbox>
                <el-checkbox-group v-model="item.dayOfWeek" @change="handleCheckedChange($event,index)">
                  <el-checkbox v-for="obj in allOptions" :label="obj" :key="obj" name="time"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </div>
          </el-form>
          <div style="text-align: center;margin-top: 50px">
            <el-button type="success" @click="addDateItem('formDate')" plain>添加时间段</el-button>
          </div>

        </el-col>
      </el-row>

    </el-drawer>
  </div>
</template>

<script>
const allOptions = ['1', '2', '3', '4', '5', '6', '7'];
export default {
  name: "manage",
  data() {
    return {
      addFlag: true, // 新增标识
      allOptions: allOptions,
      drawer: false,  // 抽屉
      drawerTitle: '', // 抽屉标题
      delItemIds: [], // 删除的id
      form: {
        roomName: '',
        roomDescription: '',
        peopleNum: '',
        tag: [],
        isActive: 0,
      },
      infoRules: {
        roomName: [
          {required: true, trigger: "blur", message: "请输入会议室名称"},
        ],
        peopleNum: [
          {required: true, trigger: "blur", message: "请输入容纳人数"},
          {min: 1, max: 200, message: '人数在 1 - 200', trigger: 'blur'}
        ],
        tag: [
          {required: true, trigger: "blur", message: "请选择设施情况"},
        ]
      },
      formDate: [
        {
          startTime: '',
          endTime: '',
          dayOfWeek: [],
          isIndeterminate: true,
          checkAll: false,
        }
      ],
      tableData: []
    }
  },
  created() {
    this.list()
  },

  methods: {

    /**
     * 获取会议室列表
     */
    list() {
      this.$apiUtil.Manage.meetingList(null, res => {
        let list = res.data;
        this.tableData = list;
      })
    },

    /**
     * 新增会议室
     */
    addMeeting() {
      this.drawerTitle = '添加会议室';
      this.drawer = true;
      this.addFlag = true
      this.clearForm();

    },

    /**
     * 保存
     */
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let roomItems = JSON.parse(JSON.stringify(this.formDate));
          let request = {
            roomName: this.form.roomName,
            roomDescription: this.form.roomDescription,
            isActive: this.form.isActive=='0',
            peopleNum: this.form.peopleNum,
            tag: this.form.tag.join(',')
          }

          // 遍历 roomTimeList 中的每个对象
          for (let i = 0; i < roomItems.length; i++) {
            // 将 dayOfWeek 数组转为逗号分隔的字符串
            roomItems[i].dayOfWeek = roomItems[i].dayOfWeek.join(",");
          }
          // 新增
          request.roomTimeList = roomItems;
          if (this.addFlag) {
            this.$apiUtil.Manage.addRoom(request, res => {
              this.$message({
                message: '新增成功',
                type: 'success',
                center: true,
                duration: 1000,
                onClose: () => {
                  this.drawer = false;
                  this.clearForm();
                  this.list();
                }
              });
            })
          } else {
            request.id = this.form.roomId;
            // // 编辑
            // // 遍历 roomTimeList 中的每个对象
            // // 新增日期
            // for (let i = 0; i < roomItems.length; i++) {
            //   let roomItem = roomItems[i];
            //   if(!roomItem.hasOwnProperty ('id')){
            //     roomItem.roomId = this.form.roomId;
            //     this.$apiUtil.Manage.addRoomTime(roomItem, res => {})
            //   }
            // }

            this.$apiUtil.Manage.updateRoom(request, res => {

              // 删除日期
              for (let i = 0; i < this.delItemIds.length; i++) {
                let id = this.delItemIds[i];
                this.$apiUtil.Manage.delRoomTime({id: id}, res => {
                })
              }

              this.$message({
                message: '修改成功',
                type: 'success',
                center: true,
                duration: 1000,
                onClose: () => {
                  this.drawer = false;
                  this.clearForm();
                  this.list();
                }
              });
            })

          }

        }
      })

    },

    // 全选
    handleCheckAllChange(val, index) {
      this.formDate[index].dayOfWeek = val ? allOptions : [];
      this.formDate[index].isIndeterminate = false;
    },

    // 多选框
    handleCheckedChange(value, index) {
      let checkedCount = value.length;
      this.formDate[index].checkAll = checkedCount === this.allOptions.length;
      this.formDate[index].isIndeterminate = checkedCount > 0 && checkedCount < this.allOptions.length;
    },

    // 清空表单
    clearForm() {
      this.$refs.form.resetFields();
      this.form = {
        roomName: '',
        roomDescription: '',
        peopleNum: '',
        tag: [],
        isActive: 0,
      };
      this.formDate = [
        {
          startTime: '',
          endTime: '',
          dayOfWeek: [],
          isIndeterminate: true,
          checkAll: false,
        }
      ]
    },

    // 删除项目
    delItem(item, index) {
      this.formDate.splice(index, 1)
      if (item.id) {
        this.delItemIds.push(item.id)
      }
    },

    // 新增项目
    addDateItem(formName) {
      for (let i = 0; i < this.formDate.length; i++) {
        const item = this.formDate[i];
        if (!item.startTime || !item.endTime || !item.dayOfWeek.length) {
          this.$message.error('请填写完整的日期和时间信息');
          return false; // 返回 false 表示验证失败
        }
      }
      this.formDate.push({
        startTime: '',
        endTime: '',
        dayOfWeek: [],
        isIndeterminate: true,
        checkAll: false,
      })
    },
    /**
     * 编辑
     */
    handleEdit(index, row) {
      this.addFlag = false; // 编辑标识
      this.delItemIds = []; // 清空删除的id
      this.drawerTitle = '编辑会议室'; // 抽屉标题
      this.drawer = true;
      this.form = {
        isActive:row.isActive?0:1,
        roomName: row.roomName,
        roomDescription: row.roomDescription,
        peopleNum: row.peopleNum + '',
        tag: row.tag.split(','),
        roomId: row.id
      }
      this.$apiUtil.Manage.meetingDetail({roomId: row.id}, res => {

        let list = res.data;
        this.formDate = list;
        // 遍历 roomTimeList 中的每个对象
        for (let i = 0; i < this.formDate.length; i++) {
          // 将 dayOfWeek 字符串转为数组
          this.formDate[i].dayOfWeek = this.formDate[i].dayOfWeek.split(",");
        }

      })
    },
    /**
     * 删除
     */
    handleDelete(index, row) {
      this.$confirm('是否删除 ' + row.roomName + ' 会议室?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let request = {
          roomId: row.id
        }
        this.$apiUtil.Manage.delRoom(request, res => {
          this.$message({
            message: '删除成功',
            type: 'success',
            center: true,
            duration: 2000,
            onClose: () => {
              this.list();
            }
          });
        })
      }).catch(() => {
      });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    }
  }
}
</script>

<style scoped>
.cx-del-btn {
  position: relative;
  left: 92%
}

.cx-root {
  padding: 1% 5%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
}

.cx-add-btn {
  float: right;
}

.cx-form-date {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  padding: 3% 3% 1% 3%;
  margin-bottom: 20px;
  width: 90%;
}

.cx-form-title {
  text-align: center;
  margin-bottom: 20px;
}

.el-checkbox {
  margin-right: 15px;
}

.cx-save-div {

  margin-left: 10%;
}

.cx-save-div > .el-button {
  width: 80%;
}

</style>