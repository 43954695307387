<template>
  <div class="cx-root">

    <div class="cx-body">
      <p>我的预约</p>
      <el-table
          :data="tableData"
          stripe
          style="width: 100%">
        <el-table-column
            prop="meetName"
            label="会议名称">
        </el-table-column>
        <el-table-column
            prop="roomName"
            label="会议室名称">
        </el-table-column>
        <el-table-column
            prop="date"
            label="日期">
        </el-table-column>
        <el-table-column
            prop="time"
            label="时间">
        </el-table-column>
        <el-table-column
            prop="auditName"
            label="状态">
          <template slot-scope="scope">
            <el-tag
                :type="scope.row.auditName === '审核通过' ? 'success' : scope.row.auditName === '已拒绝'? 'danger' : 'primary'"
                disable-transitions>{{scope.row.auditName}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div>
              <el-button
                  v-if="scope.row.auditName !== '审核通过'"
                  size="mini"
                  type="primary"
                  @click="handleEdit(1,scope.$index, scope.row)">修改预约
              </el-button>
              <el-button
                  size="mini"
                  type="danger"
                  @click="handleEdit(2,scope.$index, scope.row)">取消预约
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="预定" :visible.sync="orderVisible" width="30%">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="会议名称" label-width="120px" prop="meetName">
          <el-input v-model="form.meetName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="预约人" label-width="120px" prop="nickname">
          <el-input v-model="form.nickname" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="学号" label-width="120px" prop="stuNumber">
          <el-input v-model="form.stuNumber" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="手机号" label-width="120px" prop="phoneNumber">
          <el-input v-model="form.phoneNumber" autocomplete="off" type="text" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitOrder">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "my",
  data() {
    return {
      drawer: false, // 抽屉
      date: new Date(),
      tableData:[],
      auditName: ['待审核', '审核通过', '已拒绝'],
      orderVisible:false, // 预定框
      form: {
        meetName: '',
        nickname: '',
        stuNumber: '',
        phoneNumber: ''
      },
      rules: {
        meetName: [
          {required: true, message: '请输入会议名称', trigger: 'blur'},
        ],
        nickname: [
          {required: true, message: '请输入预约人', trigger: 'blur'},
        ],
        stuNumber: [
          {required: true, message: '请输入学号', trigger: 'blur'},
        ],
        phoneNumber: [
          {required: true, message: '请输入手机号', trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    this.mydate();
    this.form.nickname = this.$cookies.get('nickname')
    this.form.stuNumber = this.$cookies.get('stuNumber')
    this.form.phoneNumber = this.$cookies.get('phoneNumber')
  },
  methods: {

    submitOrder(){
      let that = this;
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$apiUtil.User.updateOrder(this.form,res=>{
            this.$message({
              message: '修改成功',
              type: 'success',
              center: true,
              duration: 1000,
              onClose: () => {
                that.orderVisible = false;
                this.mydate();
              }
            });

          },err=>{})
        } else {
          return false;
        }
      });
    },

    handleEdit(type,index, row) {
      if(type === 1){
        this.form.meetName = row.meetName;
        this.form.phoneNumber = row.phoneNumber;
        this.form.id = row.id;
        this.form.timeId = row.roomTimeId;
        this.form.date = row.date.replaceAll("-","")
        this.orderVisible = true
      }else if(type === 2){
        this.$confirm('确定取消预定？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$apiUtil.User.cancel({id:row.id},res=>{
            this.$message({
              message: '取消成功',
              type: 'success'
            });
            this.mydate();
          },err=>{})
        }).catch(() => {});

      }
    },

    // 获取我的预约
    mydate(){
      this.$apiUtil.User.mydate(null,res=>{
        let list = res.data;
        for (let i = 0; i < list.length; i++) {
          list[i].tags = list[i].tag.split(",")
          list[i].date = list[i].startTime.substr(0,10)
          list[i].time = list[i].startTime.substr(11,5) + ' - ' + list[i].endTime.substr(11,5)
          list[i].auditName = this.auditName[list[i].audit]
        }
        this.tableData = list;
      },err=>{

      })
    }

  },
}
</script>

<style scoped>

.cx-root {
  padding: 1% 5%;
  /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);*/
  /*border-radius: 15px;*/
}
.cx-body-card{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  background-color: white;
  border-radius: 10px;
  padding: 3%;
}

.cx-card > div{
  text-align: center;
  background-color: beige;
  height: 200px;
  line-height: 200px;
  border-radius: 30px;
}
.cx-body{
  background-color: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  width: 90%;
  min-height: 300px;
  margin-top: 50px;
  padding: 2% 5% 5% 5%;

}
.cx-body > p{
  font-weight: bold;
  font-size: 25px;
}

</style>