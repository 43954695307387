<template>
  <div>
    <div class="app-container home">
      <el-row  type="flex" justify="space-around" class="row-bg">
        <el-card class="box-card cardDiv1">
          <el-col :span="6">
            <div class="box-div card1">
              <div class="webBox">
                <div class="webO">会议室数量:</div>
                <div class="webT">{{roomNumber}}</div>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="box-div card2">
              <div class="webBox">
                <div class="webO">学生数量:</div>
                <div class="webT">{{ studentNumber }}</div>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="box-div card3">
              <div class="webBox">
                <div class="webO">教师数量:</div>
                <div class="webT">{{ teacherNumber }}</div>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="box-div card4">
              <div class="webBox">
                <div class="webO">预定数量:</div>
                <div class="webT">{{ unReservationNumber }}</div>
              </div>
            </div>
          </el-col>

        </el-card>

      </el-row>
    </div>

    <div class="cx-root">
      <div class="cx-root-body">
        <div>
          <tex>待审批列表</tex>
        </div>

        <el-divider></el-divider>


        <el-table
            :data="tableData"
            style="width: 100%">
          <el-table-column
              prop="roomName"
              label="会议室名称"
              min-width="200px"
          >
          </el-table-column>
          <el-table-column
              prop="date"
              label="日期">
          </el-table-column>
          <el-table-column
              prop="time"
              label="时间段"
              width="180">
          </el-table-column>
          <el-table-column
              prop="meetName"
              label="会议名称">
          </el-table-column>
          <el-table-column
              prop="nickname"
              label="申请人">
          </el-table-column>
          <el-table-column
              prop="stuNumber"
              label="学号">
          </el-table-column>
          <el-table-column
              prop="phoneNumber"
              label="申请人手机号">
          </el-table-column>
          <el-table-column label="操作" min-width="200px">
            <template slot-scope="scope">
              <div v-if="scope.row.audit===0">
                <el-button
                    size="mini"
                    type="success"
                    @click="handleEdit(1,scope.$index, scope.row)">通过
                </el-button>
                <el-button
                    size="mini"
                    type="danger"
                    @click="handleEdit(2,scope.$index, scope.row)">拒绝
                </el-button>
              </div>
              <div v-if="scope.row.audit===1">
                <el-tag type="success">审核通过</el-tag>
              </div>
              <div v-if="scope.row.audit===2">
                <el-tag type="danger">【已拒绝】{{ scope.row.auditReason }}</el-tag>
              </div>

            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: "index",
  data() {
    return {
      roomNumber: 0,
      studentNumber: 0,
      teacherNumber: 0,
      unReservationNumber: 0,
      tableData: [],
    }
  },
  created() {
    this.indexInfo();
    this.list();
  },
  methods: {
    list() {
      this.$apiUtil.Manage.dating({auditStatus: 0}, res => {
        this.tableData = res.data;
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i].date = this.tableData[i].startTime.substr(0,10)
          this.tableData[i].time = this.tableData[i].startTime.substr(11,5) + ' - ' + this.tableData[i].endTime.substr(11,5)
        }
      })
    },

    indexInfo() {
      this.$apiUtil.Manage.manageIndex(null, res => {
        let data = res.data;
        this.roomNumber = data.roomNumber;
        this.studentNumber = data.studentNumber;
        this.teacherNumber = data.teacherNumber;
        this.unReservationNumber = data.unReservationNumber;
      })
    },
    /**
     * 审核会议室
     * @param data
     */
    audit(data){
      this.$apiUtil.Manage.datingAudit(data,res=>{
        this.$message({
          message: '保存成功',
          type: 'success',
          center: true,
          duration: 1000,
          onClose: () => {
            this.list();
          }
        });
      })
    },

    /**
     * 审核
     */
    handleEdit(audit,index,row){
      if(audit===1){
        // 通过
        this.$confirm('确认通过 '+row.nickname + ' 申请的 ' + row.roomName, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {
            id:row.id,
            audit:audit
          }
          this.audit(data);
        }).catch(() => {});
      }else {
        // 拒绝
        this.$prompt('请输入审核原因', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(({ value }) => {
          if(value.trim()){
            let data = {
              id:row.id,
              audit:audit,
              auditReason:value,
            }
            this.audit(data);
          }else {
            this.$message.error('请输入审核原因');
          }
        }).catch(() => { });
      }
    },
  }
}
</script>

<style scoped >
.cx-root {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  width: 97%;
  margin-left: 1.5%;
}
.cx-root-body{
  padding: 40px;
}

.box-div{
  height: 120px;
  padding: 35px;
  margin: 20px;
}
.cardDiv1{
  width: 100%;
  margin-bottom: 10px;
}
.app-container {
  padding: 20px;
  background: #eeeeee50;
}

ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}
.webBox{
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.webO{
  display: -webkit-box;
  -webkit-box-pack: start;
  -webkit-box-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}
.webT{
  display: -webkit-box;
  -webkit-box-pack: end;
  -webkit-box-align: center;
  font-size: 50px;
  font-weight: bold;
  padding-right: 50px;
  padding-top: 10px;
  color: #fff;
}
.card1{
  background: linear-gradient(180deg, rgba(30, 198, 149, 1) 0%, rgba(30, 198, 149, 1) 0%, rgba(51, 204, 204, 1) 100%, rgba(51, 204, 204, 1) 100%);
  border: none;
  border-radius: 4px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.349019607843137);
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
}
.card2{
  background: linear-gradient(90deg, rgba(244, 174, 149, 1) 0%, rgba(244, 174, 149, 1) 0%, rgba(226, 113, 140, 1) 100%, rgba(226, 113, 140, 1) 100%);
  border: none;
  border-radius: 4px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.349019607843137);
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
}
.card3{
  background: linear-gradient(180deg, rgba(255, 153, 51, 1) 0%, rgba(255, 153, 51, 1) 0%, rgba(239, 203, 45, 1) 100%, rgba(239, 203, 45, 1) 100%);
  border: none;
  border-radius: 4px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.349019607843137);
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
}
.card4{
  background: linear-gradient(180deg, rgba(45, 169, 250, 1) 0%, rgba(45, 169, 250, 1) 0%, rgba(102, 204, 255, 1) 100%, rgba(102, 204, 255, 1) 100%);
  border: none;
  border-radius: 4px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.349019607843137);
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
}
.card5{
  background: linear-gradient(180deg, rgba(170, 137, 254, 1) 0%, rgba(170, 137, 254, 1) 0%, rgba(204, 153, 204, 1) 100%, rgba(204, 153, 204, 1) 100%);
  border: none;
  border-radius: 4px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.349019607843137);
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
}



</style>