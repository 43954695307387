import Vue from 'vue'
import cookies from "vue-cookies";
import VueRouter from 'vue-router'
import ManageLogin from '../views/manage/login.vue'
import ManageIndex from '../views/manage/index.vue'
import ManageManage from '../views/manage/manage.vue'
import ManageUser from '../views/manage/user.vue'
import ManageReserve from '../views/manage/reserve.vue'
import ManageMenu from '../views/manage/menu/index.vue'

import WebsiteMenu from '../views/website/menu/index.vue'
import WebsiteIndex from "@/views/website";  // 默认加载index.vue
import WebsiteMy from "@/views/website/my.vue";  // 默认加载index.vue
import WebsiteLogin from "@/views/website/login.vue";
import WebsiteIdle from "@/views/website/idle.vue";
import WebsiteCalendar from "@/views/website/calendar.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/manage/login',
        name: 'login', // 路由名称
        component: ManageLogin // 组件对象
    },

    {
        path: '/manage/',
        component: ManageMenu,
        children: [
            {
                path: '/manage/',
                component: ManageIndex,
                meta: {title: '会议室首页', requireAuth: true}
            }
        ]
    },
    {
        path: '/manage/manage/',
        component: ManageMenu,
        children: [
            {
                path: '/manage/manage/',
                component: ManageManage,
                meta: {title: '会议室管理', requireAuth: true}
            }
        ]
    }, {
        path: '/manage/user',
        component: ManageMenu,
        children: [
            {
                path: '/manage/user',
                component: ManageUser,
                meta: {title: '用户管理', requireAuth: true}
            }
        ]
    }, {
        path: '/manage/reserve',
        component: ManageMenu,
        children: [
            {
                path: '/manage/reserve',
                component: ManageReserve,
                meta: {title: '预定管理', requireAuth: true}
            }
        ]
    },

//    website
    {
        path: '/login',
        name: 'login', // 路由名称
        component: WebsiteLogin // 组件对象
    },
    {
        path: '/',
        component: WebsiteMenu,
        children: [
            {
                path: '/',
                component: WebsiteIndex,
                meta: {title: '预约会议室'}
            }
        ]
    },
    {
        path: '/my',
        component: WebsiteMenu,
        children: [
            {
                path: '/my',
                component: WebsiteMy,
                meta: {title: '我的预定'}
            }
        ]
    },
  {
    path: '/idle',
    component: WebsiteMenu,
    children: [
      {
        path: '/idle',
        component: WebsiteIdle,
        meta: {title: '空闲会议室'}
      }
    ]
  },
  {
    path: '/calendar',
    component: WebsiteMenu,
    children: [
      {
        path: '/calendar',
        component: WebsiteCalendar,
        meta: {title: '会议日历'}
      }
    ]
  },
]


const router = new VueRouter({
    mode: 'history',  //去掉url中的#
    base: "/",
    routes
})

//登录拦截
router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) {  // 判断该路由是否需要登录权限
        //如果需要就执行下面的代码

        let token = cookies.get("manageToken")
        if (token) {
            next();
        } else {
            next({
                path: '/manage/login',//返回登录界面
            })
        }
    } else {
        //如果不需要登录权限就直接跳转到该路由
        next();
    }
})

export default router
