<template>
  <!-- logo和文字 -->
  <div class="header">
    <!--  下拉菜单-->
    <el-dropdown @command="handleCommand"><!--  绑定指令,在methods里定义-->
      <span class="el-dropdown-link">
      {{ username }}
      <i class="el-icon-arrow-down el-icon--right"></i>
    </span>
      <el-dropdown-menu slot="dropdown">
        <!-- icon是修改图标 ，command是点击后传给方法的值-->
<!--        <el-dropdown-item icon="el-icon-edit" command="edit">>修改密码</el-dropdown-item>-->
        <el-dropdown-item icon="el-icon-s-operation" command="quit">>退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <!--  下拉菜单结束-->


  </div>

</template>

<script>
export default {
  data() {
    return {
      username: ''
    }
  },
  created() {
    this.username = this.$cookies.get('nickname')
  },
  methods: {
    handleCommand(command){
      if(command === 'quit'){
        this.$confirm('是否退出登录？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         // 退出登录
          this.$cookies.remove('nickname');
          this.$cookies.remove('userToken');
          this.$cookies.remove('stuNumber');
          this.$router.push('/login');
          this.$message({
            type: 'success',
            message: '退出成功!'
          });
        }).catch(() => {
          // 取消退出
        });

      }
    }
  },
}
</script>

<style scoped>
/* logo */
.logo {
  vertical-align: middle; /* 居中 */
  padding: 0px 10px 0px 40px; /* 上右下左 */
}

/* 文字 */
.company {
  position: absolute;
}

/* 下拉菜单 */
.el-dropdown{
  float: right; /* 浮动在右边 */
  margin-right: 40px; /* 靠右40px */
}
/* 系统管理 */
.el-dropdown-link{
  cursor: pointer; /* 鼠标放上去是手的形状 */
}
</style>

